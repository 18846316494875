import React from 'react';

export const ServicesLists: React.FC = () => {
  return (
      <div className='services-list-grid__container mx-auto mx-0 flex flex-col justify-center mb-6'>
        <ul className='services-list-grid__row services-list'>
          <li className='services-list-grid'>
            <h4>Cloud Accounting Set Up</h4>
            <ul>
              <li><strong>See your numbers anytime.</strong></li>
              <li>Tailored to your business—no more manual work.</li>
              <li>Save hours every week with seamless automation.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Done-For-You Standard Operating Procedures</h4>
            <ul>
              <li>Get clear, documented processes done for you.</li>
              <li>Collect all your data in one place, easily.</li>
              <li><strong>Free up your team’s time</strong> with fast, efficient processes.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Weekly Bookkeeping</h4>
            <ul>
              <li>Keep your records up to date without lifting a finger.</li>
              <li>Be audit-ready with clean, accurate data.</li>
              <li>Close books in 5 days—<strong>get real-time insights</strong> fast.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Simple Financial Reports</h4>
            <ul>
              <li>Easy-to-read reports, delivered every week or month.</li>
              <li><strong>Track each client’s and department’s performance.</strong></li>
              <li><strong>Instant updates,</strong> so you’re always in control.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Fractional CFO Services to Help You Scale</h4>
            <ul>
              <li><strong>Expert advice</strong> to grow faster and profit more.</li>
              <li>Smart cash flow and budgeting plans for your business.</li>
              <li>Track the metrics that drive success.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Effortless Payroll Management</h4>
            <ul>
              <li><strong>Pay your team automatically,</strong> no manual work needed.</li>
              <li>Stay compliant without the hassle.</li>
              <li>Secure, automated systems that save you time.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Real-Time Accounts Payable</h4>
            <ul>
              <li>Automate payments and track them <strong>in real-time.</strong></li>
              <li>Boost cash flow with less effort.</li>
              <li>Match payments with vendors automatically—no more mistakes.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Automatic Tax Payments</h4>
            <ul>
              <li>Never miss a tax deadline with <strong>automatic payments.</strong></li>
              <li><strong>No penalties</strong>—ever.</li>
              <li>File on time, every time, with zero stress.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Smart Corporate Tax Savings</h4>
            <ul>
              <li><strong>Pay less in business taxes</strong> with expert plans.</li>
              <li>Stay on top of CRA payments without hassle.</li>
              <li>Save money <strong>quickly</strong> with proactive tax strategies.</li>
            </ul>
          </li>
          <li className='services-list-grid'>
            <h4>Easy Personal Tax Savings</h4>
            <ul>
              <li>Get help with <strong>complex tax issues</strong>—no stress.</li>
              <li><strong>Maximize your savings</strong> with custom tax strategies.</li>
              <li>File on time without worrying about penalties.</li>
            </ul>
          </li>
        </ul>
      </div>
  );
};